import { inject } from '@angular/core';
import { CanDeactivateFn } from '@angular/router';
import { Observable } from 'rxjs';

import { GeoPhotoItemStore } from '../../data-access';

export interface CanComponentDeactivate {
	canDeactivate: () => Observable<boolean> | Promise<boolean> | boolean;
}

export const ResetGuard: CanDeactivateFn<CanComponentDeactivate> = (): boolean => {
	const store = inject(GeoPhotoItemStore);
	store.reset();
	return true;
};

export const ResetItemGuard: CanDeactivateFn<CanComponentDeactivate> = (): boolean => {
	const store = inject(GeoPhotoItemStore);
	store.resetFilter();
	return true;
};
