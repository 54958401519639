import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';

import { ModelStore, PostStore } from '../../data-access';

export const categoryResolver: ResolveFn<boolean> = (route): boolean => {
	const store = inject(PostStore);
	const modelStore = inject(ModelStore);

	store.getPosts({
		appId: route.params['appId'],
		modelId: route.params['category']
	});

	modelStore.selectModel(route.params['category']);

	return true;
};
