import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { ApplicationConfig, importProvidersFrom, isDevMode } from '@angular/core';
import { provideAnimations } from '@angular/platform-browser/animations';
import {
	PreloadAllModules,
	provideRouter,
	withComponentInputBinding,
	withPreloading,
	withRouterConfig
} from '@angular/router';
import { provideServiceWorker } from '@angular/service-worker';
import { DefaultOptions } from '@apollo/client';
import { provideHotToastConfig } from '@ngneat/hot-toast';
import { provideRouterStore, routerReducer } from '@ngrx/router-store';
import { provideStore } from '@ngrx/store';

import { provideAuthConfig } from '@yuno/angular-auth';
import { GraphqlAngularModule } from '@yuno/angular-graphql';
import { ApiModule } from '@yuno/angular/api';
import { ENVIRONMENT } from '@yuno/yuno/core';

import { environment } from '../environments/environment';
import { appRoutes } from './app.routes';

const defaultApolloOptions: DefaultOptions = {
	watchQuery: {
		fetchPolicy: 'no-cache',
		errorPolicy: 'ignore'
	},
	query: {
		fetchPolicy: 'no-cache',
		errorPolicy: 'all'
	},
	mutate: {
		fetchPolicy: 'no-cache',
		errorPolicy: 'all'
	}
};

export const appConfig: ApplicationConfig = {
	providers: [
		// provideExperimentalZonelessChangeDetection(),
		provideRouter(
			appRoutes,
			withComponentInputBinding(),
			withRouterConfig({ paramsInheritanceStrategy: 'always' }),
			withPreloading(PreloadAllModules)
		),

		provideServiceWorker('ngsw-worker.js', {
			enabled: !isDevMode(),
			registrationStrategy: 'registerWhenStable:30000'
		}),
		provideAnimations(),
		provideStore({
			router: routerReducer
		}),
		provideRouterStore(),
		provideHttpClient(withInterceptorsFromDi()),
		provideHotToastConfig({
			style: {
				border: '1px solid #171c23', // yuno-gray-800
				padding: '0.75rem 1rem',
				color: '#b0b5bc', // yuno-gray-200
				backgroundColor: '#2e3746' // yuno-gray-600
			}
		}),
		provideAuthConfig({ redirect: ['./'], authUrl: environment['yuno-api'] }),
		importProvidersFrom(
			GraphqlAngularModule.withConfig({
				uri: environment['yuno-graphql'],
				defaultOptions: defaultApolloOptions
			}),
			ApiModule.forRoot({
				url: environment['yuno-api'],
				cdn_url: environment['yuno-cdn-api-storage']
			})
		),
		{
			provide: ENVIRONMENT,
			useValue: environment || {}
		}
	]
};
