import { inject } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { ResolveFn } from '@angular/router';
import { Observable, filter, map, tap } from 'rxjs';

import { GeoPhotoItemStore } from '../../data-access';

export const itemResolver: ResolveFn<boolean> = (route): boolean | Observable<boolean> => {
	const store = inject(GeoPhotoItemStore);

	const item = route.params['item'];
	if (item === 'new') {
		// We have to wait for the store to finish loading
		// items from the Database before we can create a new item
		return toObservable(store.isLoading).pipe(
			filter(isLoading => !isLoading),
			tap(() => {
				store.createNew();
			}),
			map(() => true)
		);
	}

	store.setFilter(route.params['item']);
	return true;
};
