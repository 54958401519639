import { Route } from '@angular/router';

import { ResetRouteLevel, routeLevelsResolver, uiContainerResolver } from '@yuno/yuno/core';

import {
	ResetGuard,
	ResetItemGuard,
	categoriesGuard,
	categoryResolver,
	itemResolver
} from './utils';

const itemRoute: Route = {
	canDeactivate: [ResetItemGuard],
	resolve: {
		item: itemResolver
	},
	loadComponent: () => import('./features').then(c => c.ItemComponent),
	children: [
		{
			path: 'data',
			resolve: [uiContainerResolver, routeLevelsResolver],
			data: { routeLevels: 2 },
			loadComponent: () =>
				import('./features').then(c => c.YunoMarkerGeoPhotoItemDataViewerComponent)
		},
		{
			path: 'location',
			resolve: [uiContainerResolver, routeLevelsResolver],
			data: { containerPosition: 'absolute', routeLevels: 2 },
			loadComponent: () =>
				import('./features').then(c => c.YunoMarkerGeoPhotoItemMapViewerComponent)
		},
		{
			path: '',
			redirectTo: 'data',
			pathMatch: 'full'
		}
	]
};

export const YunoMarkersGeoPhotoRoutes: Route[] = [
	{
		path: '',
		canActivate: [categoriesGuard],
		children: [
			{
				path: '',
				loadComponent: () => import('./features').then(c => c.GeoPhotoCategoriesComponent)
			},
			{
				path: ':category',
				canDeactivate: [ResetGuard, ResetRouteLevel],
				resolve: {
					category: categoryResolver
				},
				children: [
					{
						path: '',
						loadComponent: () => import('./features').then(c => c.CategoryComponent),
						children: [
							{
								path: 'map',
								resolve: [uiContainerResolver, routeLevelsResolver],
								data: { containerPosition: 'absolute', routeLevels: 2 },
								loadComponent: () =>
									import('./features').then(
										c => c.YunoMarkerGeoPhotoMapViewerComponent
									)
							},
							{
								path: 'list',
								resolve: [uiContainerResolver, routeLevelsResolver],
								data: { routeLevels: 2 },
								loadComponent: () =>
									import('./features').then(
										c => c.YunoMarkerGeoPhotoListViewerComponent
									)
							},
							{
								path: '',
								redirectTo: 'list',
								pathMatch: 'full'
							}
						]
					},
					{
						path: 'map/:item',
						...itemRoute
					},
					{
						path: 'list/:item',
						...itemRoute
					},
					{
						path: '',
						redirectTo: 'list',
						pathMatch: 'full'
					}
				]
			}
		]
	}
];
