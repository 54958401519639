import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanDeactivateFn, RouterStateSnapshot } from '@angular/router';

import { CanComponentDeactivate } from '@yuno/yuno/core';
import { AppListStore } from '@yuno/yuno/features/app-list';
import { AppStore } from '@yuno/yuno/features/functions';
import { ModelStore } from '@yuno/yuno/features/participate';

export const ResetStoresGuard: CanDeactivateFn<CanComponentDeactivate> = (
	component: CanComponentDeactivate,
	currentRoute: ActivatedRouteSnapshot,
	currentState: RouterStateSnapshot,
	nextState: RouterStateSnapshot
): boolean => {
	// Get Stores to Reset
	const appStore = inject(AppStore);
	const appListStore = inject(AppListStore);
	const participateModelStore = inject(ModelStore);

	// Get data to compare
	const data = appStore.data();

	// When current Route and AppId are the same, or the next Route still includes the appId, do not reset the store
	if (data?._id && nextState.url.includes(data?._id)) {
		return true;
	}

	// reset app list, to reload all data
	appListStore.reset();

	// reset current app
	appStore.reset();

	// reset current participation
	participateModelStore.reset();

	return true;
};
