/* eslint-disable @typescript-eslint/no-explicit-any */

/* eslint-disable @typescript-eslint/no-unused-vars */
import { GLOBAL_ENV } from '../../../../_environments/types';
import { globalEnvironment } from './../../../../_environments/environment.global.dev';

declare let require: any;
export const environment: GLOBAL_ENV = {
	...globalEnvironment
};
