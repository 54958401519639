import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';

import { PostStore } from '../../data-access';

export const itemResolver: ResolveFn<boolean> = (route): boolean => {
	const store = inject(PostStore);

	store.setFilter(route.params['item']);

	return true;
};
