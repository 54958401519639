import { Routes } from '@angular/router';

import { RoleGuard, appRoleResolver } from '@yuno/angular-auth';
import { AppGuard, appViewResolver, navbarResolver, uiContainerResolver } from '@yuno/yuno/core';
import { YunoMarkersGeoPhotoRoutes } from '@yuno/yuno/features/markers';
import { YunoParticipateRoutes } from '@yuno/yuno/features/participate';

import { ResetStoresGuard } from '../../../utils/guards/reset.guard';
import { InternalComponent } from '../internal.component';

export const InternalRoutes: Routes = [
	{
		path: '',
		component: InternalComponent,
		children: [
			{
				path: 'apps',
				resolve: {
					navbarResolver,
					uiContainerResolver
				},
				data: {
					navbarTitle: 'Home',
					navbarUrl: 'apps',
					containerPosition: 'absolute'
				},
				loadComponent: () =>
					import('@yuno/yuno/features/app-list').then(m => m.YunoMobileAppListComponent)
			},
			{
				path: ':appId',
				// this also invokes the resolvers
				// when navigating between child routes
				runGuardsAndResolvers: 'always',
				canActivate: [AppGuard, RoleGuard],
				canDeactivate: [ResetStoresGuard],
				resolve: {
					viewer: appViewResolver,
					appRole: appRoleResolver
				},
				children: [
					{
						path: '',
						loadComponent: () =>
							import('@yuno/yuno/features/functions').then(
								m => m.YunoMobileFunctionsComponent
							)
					},
					{
						path: 'participate',
						children: YunoParticipateRoutes
					},
					{
						path: 'markers-geophoto',
						children: YunoMarkersGeoPhotoRoutes
					},
					{
						path: 'view',
						resolve: [uiContainerResolver],
						data: {
							viewer: true,
							containerPosition: 'absolute'
						},
						loadComponent: () =>
							import('@yuno/yuno/features/app-viewer').then(m => m.AppViewerComponent)
					},
					{
						path: 'view-internal',
						resolve: [uiContainerResolver],
						data: {
							viewer: true,
							internal: true,
							containerPosition: 'absolute'
						},
						loadComponent: () =>
							import('@yuno/yuno/features/app-viewer').then(m => m.AppViewerComponent)
					}
				]
			},
			{ path: '', pathMatch: 'full', redirectTo: 'apps' },
			{ path: '**', pathMatch: 'full', redirectTo: 'apps' }
		]
	},
	{ path: '', pathMatch: 'full', redirectTo: 'apps' }
];
