<div class="absolute inset-0 flex flex-col">
	@if (navbarColors(); as navColors) {
		@if (navbarType(); as navType) {
			@if (navbarDisplay(); as navDisplay) {
				<nav
					class="px-safe-containers pt-safe-max flex w-full flex-row items-center gap-2 bg-yuno-blue-gray-800 pb-2 text-white transition-colors duration-300"
					[ngStyle]="{
						backgroundColor: navType === 'border' ? '#fff' : navColors.background,
						color: navColors.font
					}">
					<!-- Back Button -->
					<div class="flex flex-1 items-center justify-start">
						@if (navDisplay.display !== 'Home') {
							<button
								(click)="navigateToParentRoute()"
								class="flex items-center justify-start">
								<svg
									xmlns="http://www.w3.org/2000/svg"
									viewBox="0 0 24 24"
									fill="currentColor"
									class="mr-3 h-5 w-5">
									<path
										fill-rule="evenodd"
										d="M11.03 3.97a.75.75 0 0 1 0 1.06l-6.22 6.22H21a.75.75 0 0 1 0 1.5H4.81l6.22 6.22a.75.75 0 1 1-1.06 1.06l-7.5-7.5a.75.75 0 0 1 0-1.06l7.5-7.5a.75.75 0 0 1 1.06 0Z"
										clip-rule="evenodd" />
								</svg>
								Back
							</button>
						}
					</div>
					<!-- Title -->
					<div class="flex items-center justify-center overflow-hidden">
						<a class="truncate" [routerLink]="['./', navDisplay.url]">
							{{ navDisplay.display }}
						</a>
					</div>
					<!-- User Button -->
					<div class="flex flex-1 justify-end">
						<yuno-feature-user-dropdown />
					</div>
				</nav>
			}
		}
	}
	<div class="relative block h-full w-full overflow-y-auto bg-yuno-blue-gray-200">
		<div
			class="px-safe-containers pb-safe inset-0 flex flex-col gap-4 pt-4 sm:py-8"
			[ngClass]="containerPosition()">
			<!-- Hides the outlet container for Flex -->
			<router-outlet class="hidden"></router-outlet>
		</div>
	</div>
</div>
