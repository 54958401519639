import { inject } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { CanActivateFn } from '@angular/router';
import { Observable, filter } from 'rxjs';

import { GeoPhotoListStore } from '../../data-access';

/**
 * This guard is used to ensure that the category data is loaded before the route is activated.
 * @param route
 */
export const categoriesGuard: CanActivateFn = (route): Observable<boolean> => {
	const store = inject(GeoPhotoListStore);
	store.getApp(route.params['appId']);

	return toObservable(store.dataLoaded).pipe(filter(dataLoaded => !!dataLoaded));
};
