import { Route } from '@angular/router';

import { AuthGuard, userRoleResolver, xkpMemberResolver } from '@yuno/angular-auth';
import { ClientRoute, InternalRoute, RedirectGuard } from '@yuno/yuno/core';

import { RoutingComponent } from './components/routing/routing.component';
import { InternalRoutes } from './views/internal/_router/internal.routing';

export const appRoutes: Route[] = [
	{
		path: '',
		component: RoutingComponent,
		canActivate: [AuthGuard],
		resolve: {
			xkp: xkpMemberResolver,
			userRole: userRoleResolver
		},
		children: [
			// redirects the users to the correct url, Clients or Internal
			{ path: '', pathMatch: 'full', children: [], canActivate: [RedirectGuard] },
			{
				path: ClientRoute,
				children: InternalRoutes
			},
			{
				path: InternalRoute,
				// canActivate: [XkpGuard],
				children: InternalRoutes
			},
			{ path: '*', pathMatch: 'full', children: [], canActivate: [RedirectGuard] }
		]
	},
	{
		path: 'auth',
		loadChildren: () => import('@yuno/angular-auth').then(m => m.AuthRoutes)
	},
	{ path: '*', pathMatch: 'full', redirectTo: 'auth/login' },
	{ path: '**', redirectTo: '' }
];
