import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';

import { GeoPhotoItemStore, GeoPhotoListStore } from '../../data-access';

export const categoryResolver: ResolveFn<boolean> = (route): boolean => {
	const store = inject(GeoPhotoItemStore);
	const modelStore = inject(GeoPhotoListStore);

	if (!route.params['appId'] || !route.params['category']) {
		return false;
	}
	store.getItems({
		appId: route.params['appId'],
		categoryId: route.params['category']
	});

	modelStore.select({
		appId: route.params['appId'],
		categoryId: route.params['category']
	});

	return true;
};
